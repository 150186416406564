import * as standard from './sources/standard'
import { organizeDataSource, createFsDataRunnerBySource } from '@shein-aidc/utils-data-source-toolkit'
import type { DS_CouponList } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_CouponList.SourceParams): DS_CouponList.APIS {
  return organizeDataSource<DS_CouponList.APIS, DS_CouponList.DataSource>(
    {
      standard: {
        ...standard,
      },
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(sourceParams: DS_CouponList.FS_SourceParams) {
  const { getCommonCouponDataApi, getCouponListApi, langs } = getSource(sourceParams)
  return createFsDataRunnerBySource<DS_CouponList.FS_APIS, DS_CouponList.FS_DATA>({
    getCommonCouponDataApi,
    getCouponListApi,
    langs,
  })
}
