import type { AS_CouponList } from '../../../types'

const standard: AS_CouponList.AnalysisFuncs = {
  'expose_added_coupon.comp_coupon-list': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_scenesabt.comp_coupon-list': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_added_coupon.comp_coupon-list': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_recommend_coupon_info.comp_coupon-list': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_automatically_coupons_result.comp_coupon-list': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_apply_recommend_coupon.comp_coupon-list': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_apply_recommend_coupon.comp_coupon-list': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_couponconfirm.comp_coupon-list': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_couponaddon_banner.comp_coupon-list': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_couponaddon_banner.comp_coupon-list': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
}

export default standard
