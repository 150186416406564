import type { DS_CouponList } from '../../../types'
import { getLangs } from '@shein-aidc/bs-sdk-libs-manager'

export const getCommonCouponDataApi: DS_CouponList.APIS_THIS['getCommonCouponDataApi'] = async function (x) {
  const result = await this.$schttp<Array<number>>({
    url: '/api/coupon/getCommonCouponData/query',
    method: 'POST',
    data: {
      x,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return result.data
}

// bff获取下单券列表api
export const getCouponListApi: DS_CouponList.APIS_THIS['getCouponListApi'] = async function (url: string = '', datas: object = {}) {
  const result = await this.$schttp<Array<number>>({
    url: url,
    method: 'POST',
    data: {
      ...datas,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return result.data
}


const langsMap = {
  SHEIN_KEY_PWA_20994: 'SHEIN_KEY_PWA_20994',
  SHEIN_KEY_PWA_17288: 'SHEIN_KEY_PWA_17288',
  SHEIN_KEY_PWA_21002: 'SHEIN_KEY_PWA_21002',
  SHEIN_KEY_PWA_17978: 'SHEIN_KEY_PWA_17978',
  SHEIN_KEY_PWA_21001: 'SHEIN_KEY_PWA_21001',
  SHEIN_KEY_PWA_17182: 'SHEIN_KEY_PWA_17182',
  SHEIN_KEY_PWA_17183: 'SHEIN_KEY_PWA_17183',
  SHEIN_KEY_PWA_15095: 'SHEIN_KEY_PWA_15095',
  SHEIN_KEY_PWA_16216: 'SHEIN_KEY_PWA_16216',
  SHEIN_KEY_PWA_15104: 'SHEIN_KEY_PWA_15104',
  SHEIN_KEY_PWA_21000: 'SHEIN_KEY_PWA_21000',
  SHEIN_KEY_PWA_25058: 'SHEIN_KEY_PWA_25058',
  SHEIN_KEY_PWA_25059: 'SHEIN_KEY_PWA_25059',
  SHEIN_KEY_PWA_18263: 'SHEIN_KEY_PWA_18263',
  SHEIN_KEY_PWA_15819: 'SHEIN_KEY_PWA_15819',
  SHEIN_KEY_PWA_15797: 'SHEIN_KEY_PWA_15797',
  SHEIN_KEY_PWA_25061: 'SHEIN_KEY_PWA_25061',
  SHEIN_KEY_PWA_15116: 'SHEIN_KEY_PWA_15116',
  SHEIN_KEY_PWA_37198: 'SHEIN_KEY_PWA_37198',
  SHEIN_KEY_PWA_18957: 'SHEIN_KEY_PWA_18957',
  SHEIN_KEY_PWA_30834: 'SHEIN_KEY_PWA_30834',
}
export const langs: DS_CouponList.APIS_THIS['langs'] = async function () {
  const result = await getLangs(langsMap, this.$schttp)
  return result
}
