import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
export { default, default as BCouponList } from './coupon-list.vue'
import type { DS_CouponList } from '../../types'
export type * from '../../types'

import { getFsDataRunner } from '../../common/dataSource'

export function getCouponListFsDataRunner(sourceParams?: Partial<DS_CouponList.FS_SourceParams>) {
  return getFsDataRunner({ appConfigs: useAppConfigs(), ...sourceParams })
}
